import {ref, provide, inject} from 'vue';

function getIdGenerator() {
	let lastId = 0
	
	return function getNextUniqueId() {
			lastId += 1
			return lastId
	}
}

const getNextUniqueId = getIdGenerator()
const EventBusSymbol = Symbol();

export const createEventBus = () => ({
	subscriptions: ref({}),
	subscribe(eventType, callback) {
		const id = getNextUniqueId();
		if (!this.subscriptions[eventType]) this.subscriptions[eventType] = {};

		this.subscriptions[eventType][id] = callback;

		return {
			unsubscribe: () => {
				delete this.subscriptions[eventType][id];
				if (Object.keys(this.subscriptions[eventType]).length === 0) delete this.subscriptions[eventType];
			}
		};
	},

	publish(eventType, arg) {
		if (!this.subscriptions[eventType]) return;
		Object.keys(this.subscriptions[eventType]).forEach((key) => this.subscriptions[eventType][key](arg));
	},

	getEvents() {
		return this.subscriptions;
	}
})

export const provideEventBus = () => {
	const eventBus = createEventBus();
	provide(EventBusSymbol, eventBus)
}

export const useEventBus = () => {
	const eventBus = inject(EventBusSymbol)
	if (!eventBus) throw new Error("No eventbus provided");

	return eventBus;
}