import * as am4core from "@amcharts/amcharts4/core";
const colorSet = new am4core.ColorSet();
colorSet.maxLightness = 0.6;
colorSet.saturation = 0.6;
export const sortByKey = (array, key) => {
  return array.sort((a, b) => {
    if (typeof a[key] !== typeof b[key]) {
      return;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};

export const moveInArray = (arr, old_index, new_index) => {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
};

export const downloadFromBlob = (data, headers, filetype, regex) => {
  const url = window.URL.createObjectURL(data),
    a = document.createElement("a"),
    contentDisposition = headers.getResponseHeader("Content-Disposition");
  let fileName = false;

  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(regex);
    if (fileNameMatch.length === 2) {
      fileName = fileNameMatch[1];
      fileName = fileName.replace(/"/g, "");
    }
  }
  a.href = url;
  a.download = fileName || `po.${filetype}`;
  document.body.append(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const getLinkFromBlob = (data, headers, filetype, regex, icon) => {
  const url = window.URL.createObjectURL(data),
    a = document.createElement("a"),
    contentDisposition = headers.getResponseHeader("Content-Disposition");
  let fileName = false;

  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(regex);
    if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
  }
  return { url: url, download: fileName || `po.${filetype}` };
};

export const doValidations = (validations) => {
  for (let i = 0; i < validations.length; i++) {
    const test = validations[i];
    if (test.condition && i === validations.length - 1) {
      return {
        isValid: true,
        message: ""
      };
    }
    if (test.condition) {
      continue;
    }
    if (!test.condition) {
      return {
        isValid: false,
        message: test.message
      };
    }
  }
};

export const validate = (value) => {
  const self = {
    value: value,
    length(min, max) {
      return self.value.length >= min && value.length <= max;
    },
    onlyText() {
      return !/\d/.test(self.value);
    },
    onlyNumbers() {
      return self.value.match(/^\d*[.,]?\d*$/);
    },
    isEmail() {
      return self.value.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-åäöÅÄÖ]*[a-z0-9åäöÅÄÖ])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      );
    },
    isDate(pattern) {
      return moment(self.value, pattern).isValid();
    },
    noSpecial() {
      return !/[^a-zA-Z0-9åäöÅÄÖ\s]/.test(self.value);
    },
    presets: {
      /* No special characters, max length 255*/
      ns255() {
        return [
          {
            condition: self.noSpecial(),
            message: "Inga specialtecken"
          },
          {
            condition: self.length(0, 255),
            message: "Max 255 tecken"
          }
        ];
      },
      /* Only numbers */
      on() {
        return [
          {
            condition: self.onlyNumbers(),
            message: "Endast siffror tillåtna"
          }
        ];
      },
      /* Only text */
      ot() {
        return [
          {
            condition: self.onlyText(),
            message: "Endast text tillåtet"
          }
        ];
      },
      /* Is email*/
      email() {
        return [
          {
            condition: self.isEmail(),
            message: "Felaktig email"
          }
        ];
      }
    }
  };
  return self;
};

export const isEveryKeySet = (obj, omitted) => {
  return Object.keys(obj).every((i) => {
    const val = obj[i];
    if (omitted) {
      return omitted.includes(i) ? true : val != null && val != "" && val != undefined;
    }
    return val != null && val != "" && val != undefined;
  });
};

export const isEven = (int) => {
	return int % 2 == 0;
}

export const JSONtoCSV = (objArray, head = null) => {
  const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str =
    `${Object.keys(array[0])
      .map((value) => `"${value}"`)
      .join(",")}` + "\r\n";
  return array.reduce((str, next) => {
    str +=
      `${Object.values(next)
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    return str;
  }, str);
};

export const saveDataAsCSV = (data, filename = "") => {
  var downloadLink;
  var dataType = "text/plain;";
  filename = filename ? filename + ".csv" : "excel_data.csv";
  downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  if (navigator.msSaveOrOpenBlob) {
    var blob = new Blob(["\ufeff", tableHTML], {
      type: dataType + "charset=UTF-8;"
    });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    downloadLink.href = "data:" + dataType + ", " + data;
    downloadLink.download = filename;
    downloadLink.click();
  }
};

export const round = (value, decimals) => {
  return typeof value === "number" ? Number(Math.round(value + "e" + decimals) + "e-" + decimals) : "";
};

export const hue2rgb = (p, q, t) => {
	if (t < 0) t += 1;
	if (t > 1) t -= 1;
	if (t < 1 / 6) return p + (q - p) * 6 * t;
	if (t < 1 / 2) return q;
	if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
	return p;
}

export const hslToRgb = (h, s, l) => {
  var r, g, b;

  if (s == 0) {
    r = g = b = l; // achromatic
  } else {
    

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return { r: r * 255, g: g * 255, b: b * 255 };
};
export const rgbToHsl = (r, g, b) => {
  (r /= 255), (g /= 255), (b /= 255);

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  return { h: h, s: s, l: l };
};

export const desaturateRgb = (r, g, b, saturation) => {
  const { h, s, l } = this.rgbToHsl(r, g, b);
  return this.hslToRgb(h, s * saturation, l);
};

export const perc2color = (perc) => {
  var r,
    g,
    b = 0;
  if (perc < 50) {
    r = 255;
    g = Math.round(5.1 * perc);
  } else {
    g = 255;
    r = Math.round(510 - 5.1 * perc);
  }
  const desat = this.desaturateRgb(r, g, b, 0.5);
  var h = this.round(desat.r, 0) * 0x10000 + this.round(desat.g, 0) * 0x100 + this.round(desat.b, 0) * 0x1;
  return "#" + ("000000" + h.toString(16)).slice(-6);
};

export const generateTimestampWithKey = (obj, key) => {
  return obj[key]
    ? Math.round(new Date().getTime() / 1000) + obj[key].replace(" ", "-")
    : Math.round(new Date().getTime() / 1000);
};

export const getColorFromNumber = (number) => {
  const arr = number
    .toString()
    .split("")
    .map(Number)
    .reverse();
  const deg = (arr.reduce((a, b) => a + b, 0) * 9 * 4) % 360;
  return colorSet.getIndex(number).hex;
};

export const HSLToRGB = (h, s, l) => {
  // Must be fractions of 1
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return "rgb(" + r + "," + g + "," + b + ")";
};

export const getWeek = () => {
  return parseInt("20" + new Date().getWeek());
};

export const generateId = (name) => {
  name = name ? `${name}`.replace(" ", "-").toLowerCase() : false;
  return (
    (name ? name + "-" : "") +
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  );
};
