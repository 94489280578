export let supportsPassive = false

if (typeof window !== 'undefined') {
  supportsPassive = false
  try {
    var opts = Object.defineProperty({}, 'passive', {
      get () {
				supportsPassive = true
				return supportsPassive;
      },
    })
    window.addEventListener('test', null, opts)
  } catch (e) {
		console.error(e)
	}
}

export function getInternetExplorerVersion () {
  const ua = window.navigator.userAgent

  const msie = ua.indexOf('MSIE ')
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
  }

  const trident = ua.indexOf('Trident/')
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:')
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
  }

  const edge = ua.indexOf('Edge/')
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
  }

  // other browser
  return -1
}

export function processOptions (value) {
	let options
	if (typeof value === 'function') {
		// Simple options (callback-only)
		options = {
			callback: value,
		}
	} else {
		// Options object
		options = value
	}
	return options
}

export function throttle (callback, delay, options = {}) {
	let timeout
	let lastState
	let currentArgs
	const throttled = (state, ...args) => {
		currentArgs = args
		if (timeout && state === lastState) return
		let leading = options.leading
		if (typeof leading === 'function') {
			leading = leading(state, lastState)
		}
		if ((!timeout || (state !== lastState)) && leading) {
			callback(state, ...currentArgs)
		}
		lastState = state
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			callback(state, ...currentArgs)
			timeout = 0
		}, delay)
	}
	throttled._clear = () => {
		clearTimeout(timeout)
		timeout = null
	}
	return throttled
}

export function deepEqual (val1, val2) {
	if (val1 === val2) return true
	if (typeof val1 === 'object') {
		for (const key in val1) {
			if (!deepEqual(val1[key], val2[key])) {
				return false
			}
		}
		return true
	}
	return false
}
