import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
const colors = new am4core.ColorSet();

export const getChartColor = (item) => {
	if (typeof item.chart.color == 'string') {
		return item.chart.color;
	} else if (typeof item.chart.color == 'number') {
		return colors.getIndex(item.chart.color)
	} else {
		throw new Error(`Wrong color format in chart item type ${item.type}. Expected string or integer, got color: ${item.chart.color} of type ${typeof item.chart.color}`)
	}
}

const checkData = (title, data, axisvalues) => {
	axisvalues.forEach(item => {
		if (data.some(i => i[item] == undefined)) {
			throw new Error(`You need to provide the correct axis values in your data set for chart '${title}'.
			Axis values were: ${axisvalues.join(', ')}
			Data keys were: ${Object.keys(data[0]).join(', ')}
			`)
		}
	})
}

export const createContainer = (divId) => {
	const container = am4core.create(divId, am4core.Container);
  container.layout = "grid";
  container.fixedWidthGrid = false;
  container.width = am4core.percent(100);
	container.height = am4core.percent(100);
	return container;
}

export const createLine = (container, width, {title, data, color, axisX, axisY}) => {
	checkData(title, data, [axisX, axisY]);
	const chart = container.createChild(am4charts.XYChart);
	chart.width = am4core.percent(width);
	chart.height = 70;

	chart.data = data;

	chart.titles.template.fontSize = 10;
	chart.titles.template.textAlign = "left";
	chart.titles.template.isMeasured = false;
	chart.titles.create().text = title;

	chart.padding(20, 5, 2, 5);

	const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
	dateAxis.groupData = true;
	dateAxis.groupCount = 100;
	dateAxis.renderer.grid.template.disabled = true;
	dateAxis.renderer.labels.template.disabled = true;
	dateAxis.startLocation = 0.5;
	dateAxis.endLocation = 0.7;
	dateAxis.cursorTooltipEnabled = false;

	const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	valueAxis.min = 0;
	valueAxis.renderer.grid.template.disabled = true;
	valueAxis.renderer.baseGrid.disabled = true;
	valueAxis.renderer.labels.template.disabled = true;
	valueAxis.cursorTooltipEnabled = false;

	chart.cursor = new am4charts.XYCursor();
	chart.cursor.lineY.disabled = true;
	chart.cursor.behavior = "none";

	const series = chart.series.push(new am4charts.LineSeries());
	series.tooltipText = "{date}: [bold]{value}";
	series.dataFields.dateX = axisX;
	series.dataFields.valueY = axisY;
	series.strokeWidth = 2;
	series.stroke = color;

	// render data points as bullets
	const bullet = series.bullets.push(new am4charts.CircleBullet());
	bullet.circle.opacity = 0;
	bullet.circle.fill = color;
	bullet.circle.propertyFields.opacity = "opacity";
	bullet.circle.radius = 3;

	return chart;
}

export const createColumn = (container, width, {title, data, color, axisY, axisX}) => {
	checkData(title, data, [axisX, axisY]);
	const chart = container.createChild(am4charts.XYChart);
	chart.width = am4core.percent(width);
	chart.height = 70;

	chart.data = data;

	chart.titles.template.fontSize = 10;
	chart.titles.template.textAlign = "left";
	chart.titles.template.isMeasured = false;
	chart.titles.create().text = title;

	chart.padding(20, 5, 2, 5);

	const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
	dateAxis.renderer.grid.template.disabled = true;
	dateAxis.renderer.labels.template.disabled = true;
	dateAxis.cursorTooltipEnabled = false;

	const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
	valueAxis.min = 0;
	valueAxis.renderer.grid.template.disabled = true;
	valueAxis.renderer.baseGrid.disabled = true;
	valueAxis.renderer.labels.template.disabled = true;
	valueAxis.cursorTooltipEnabled = false;

	chart.cursor = new am4charts.XYCursor();
	chart.cursor.lineY.disabled = true;

	const series = chart.series.push(new am4charts.ColumnSeries());
	series.tooltipText = "{date}: [bold]{value}";
	series.dataFields.dateX = axisX;
	series.dataFields.valueY = axisY;
	series.strokeWidth = 0;
	series.fillOpacity = 0.5;
	series.columns.template.propertyFields.fillOpacity = "opacity";
	series.columns.template.fill = color;

	return chart;
}


export const createPie = (container, width, {data, color, value, category}) => {
	checkData('Chart of type Pie', data, [value, category]);
	
	const chart = container.createChild(am4charts.PieChart);
	chart.width = am4core.percent(width);
	chart.height = 70;
	chart.padding(20, 0, 2, 0);

	chart.data = data;

	// Add and configure Series
	const pieSeries = chart.series.push(new am4charts.PieSeries());
	pieSeries.dataFields.value = value;
	pieSeries.dataFields.category = category;
	pieSeries.labels.template.disabled = true;
	pieSeries.ticks.template.disabled = true;
	pieSeries.slices.template.fill = color;
	pieSeries.slices.template.adapter.add("fill", function(fill, target) {
		return fill.lighten(0.1 * target.dataItem.index);
	});
	pieSeries.slices.template.stroke = am4core.color("#fff");

	// chart.chartContainer.minHeight = 40;
	// chart.chartContainer.minWidth = 40;

	return chart;
}