export default function(store) {
	return {
		userCanHandleOrders: () => {
			const user = store.getters.GET_USER
			const allowedIf = [
				'Reseller'
			]
			return allowedIf.includes(user.UserGroup)
		}
	}
}