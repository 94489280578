<template>
	<GlobXpanel :title="rawName" v-if="xPanel">
		<div :id="name" :style="`height: ${height || 500}px`"></div>
	</GlobXpanel>
	<div v-else :id="name" :style="`height: ${height || 500}px`"></div>
</template>

<script>
export default {
	props: ['height', 'name', 'xPanel', 'rawName']
}
</script>