
import { provide, inject} from 'vue';
import { generateId } from '@/services/utils'

const NotificationSymbol = Symbol();

const billboard = document.getElementById('billboard');

const getIcon = (severity) => {
	let icon = '';
	switch (severity) {
		case 'warning':
			icon = 'exclamation-triangle'
			break;
		case 'error':
			icon = 'times'
			break;
		case 'success':
			icon = 'check'
			break;
	}
	return icon;
}

const createFlashNotification = (data) => {
	const container = document.createElement('div');
	const icon = document.createElement('i');
	const msg = document.createElement('span')
	icon.classList.add('fas')
	icon.classList.add(`fa-${getIcon(data.severity)}`)
	container.classList.add('ips-flash-notification');
	container.classList.add(data.severity);
	container.append(icon);
	container.append(msg);
	msg.innerHTML = data.msg;
	return container;
}

const notify = (data) => {
	const el = createFlashNotification(data)
	billboard.appendChild(el)
	setTimeout(() => {
		el.classList.add('fadeOut');
	}, 3000);
	setTimeout(() => {
		billboard.removeChild(el);
	}, 3300);
}

export const createNotifications = (store) => ({
	push(notification, severity, link, push = true, flash = true) {
		const { title, msg } = notification;
		const data = {
			title,
			msg,
			severity,
			link,
			id: generateId('notification'),
			unread: true,
			time: new Date()
		}
		if (push) store.commit('PUSH_NOTIFICATION', data)
		if (flash) notify(data);
	}
})

export const provideNotifications = (store) => {
	if (!store) throw new Error('No store provided!')
	const notificationsCenter = createNotifications(store);
	provide(NotificationSymbol, notificationsCenter)
}

export const useNotifications = () => {
	const notificationsCenter = inject(NotificationSymbol)
	if (!notificationsCenter) throw new Error('No notifications center provided');

	return notificationsCenter;
}