<template>
  <Wrapper :name="containerId" :rawName="name" :xPanel="xPanel" :height="height" />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Wrapper from "./molecules/Wrapper.vue";
import { generateId } from '@/services/utils';
import { onMounted, onUnmounted } from 'vue';
export default {
  components: {
    Wrapper
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    valueName: {
      type: String,
      required: true
    },
    innerRadius: {
      type: Number,
      required: false
    },
    height: {
      type: Number,
      required: false
    },
    xPanel: {
      required: false,
      default: true,
      type: Boolean
    }
	},
	setup(props) {
		const containerId = generateId(props.name)
		let chart = null;

		const init = () => {
			am4core.ready(() => {
        am4core.useTheme(am4themes_animated);
        chart = am4core.create(containerId, am4charts.PieChart);
        chart.data = props.data;
        // Set inner radius
        chart.innerRadius = props.innerRadius || am4core.percent(50);

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = props.value;
        pieSeries.dataFields.category = props.valueName;
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;
      });
		}

		onMounted(() => {
			init();
		})

		onUnmounted(() => {
			chart.dispose();
		})

		return {
			containerId
		}
	}
};
</script>
