<template>
  <Wrapper :name="containerId" :rawName="name" :xPanel="xPanel" :height="height" />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Wrapper from "./molecules/Wrapper.vue";
import { onMounted, watchEffect, onUnmounted, reactive, computed, ref } from "vue";
import { generateId } from "@/services/utils";
export default {
  components: {
    Wrapper
  },
  props: {
    score: {
      type: Number,
      required: true
    },
    gradingData: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    xPanel: {
      required: false,
      default: true,
      type: Boolean
    },
    height: {
      type: Number,
      required: false
    },
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    let chart = null;
    let hand = null;
    const containerId = generateId(props.name);

    const init = () => {
      am4core.ready(() => {
        am4core.useTheme(am4themes_animated);
        am4core.options.onlyShowOnViewport = true;

        const lookUpGrade = (lookupScore, grades) => {
          // Only change code below this line
          for (var i = 0; i < grades.length; i++) {
            if (grades[i].lowScore < lookupScore && grades[i].highScore >= lookupScore) {
              return grades[i];
            }
          }
          return null;
        };
        chart = am4core.create(containerId, am4charts.GaugeChart);
        chart.svgContainer.autoResize = false;
        chart.hiddenState.properties.opacity = 0;
        chart.fontSize = 11;
        chart.innerRadius = am4core.percent(80);
        chart.resizable = true;

        /**
         * Normal axis
         */

        var axis = chart.xAxes.push(new am4charts.ValueAxis());
        axis.min = props.min;
        axis.max = props.max;
        axis.strictMinMax = true;
        axis.renderer.radius = am4core.percent(80);
        axis.renderer.inside = true;
        axis.renderer.line.strokeOpacity = 0.1;
        axis.renderer.ticks.template.disabled = false;
        axis.renderer.ticks.template.strokeOpacity = 1;
        axis.renderer.ticks.template.strokeWidth = 0.5;
        axis.renderer.ticks.template.length = 5;
        axis.renderer.grid.template.disabled = true;
        axis.renderer.labels.template.radius = am4core.percent(15);
        axis.renderer.labels.template.fontSize = "0.9em";

        /**
         * Axis for ranges
         */

        var axis2 = chart.xAxes.push(new am4charts.ValueAxis());
        axis2.min = props.min;
        axis2.max = props.max;
        axis2.strictMinMax = true;
        axis2.renderer.labels.template.disabled = true;
        axis2.renderer.ticks.template.disabled = true;
        axis2.renderer.grid.template.disabled = false;
        axis2.renderer.grid.template.opacity = 0.5;
        axis2.renderer.labels.template.bent = true;
        axis2.renderer.labels.template.fill = am4core.color("#000");
        axis2.renderer.labels.template.fontWeight = "bold";
        axis2.renderer.labels.template.fillOpacity = 0.3;

        /**
			Ranges
			*/

        for (let grading of props.gradingData) {
          var range = axis2.axisRanges.create();
          range.axisFill.fill = am4core.color(grading.color);
          range.axisFill.fillOpacity = 0.8;
          range.axisFill.zIndex = -1;
          range.value = grading.lowScore > props.min ? grading.lowScore : props.min;
          range.endValue = grading.highScore < props.max ? grading.highScore : props.max;
          range.grid.strokeOpacity = 0;
          range.stroke = am4core.color(grading.color).lighten(-0.1);
          range.label.inside = true;
          range.label.text = grading.title.toUpperCase();
          range.label.inside = true;
          range.label.location = 0.5;
          range.label.inside = true;
          range.label.radius = am4core.percent(10);
          range.label.paddingBottom = -5; // ~half font size
          range.label.fontSize = "0.9em";
        }

        var matchingGrade = lookUpGrade(props.score, props.gradingData);

        /**
         * Label 1
         */

        var label = chart.radarContainer.createChild(am4core.Label);
        label.isMeasured = false;
        label.fontSize = "6em";
        label.x = am4core.percent(50);
        label.paddingBottom = 15;
        label.horizontalCenter = "middle";
        label.verticalCenter = "bottom";
        //label.dataItem = data;
        label.text = props.score.toFixed(1);
        //label.text = "{score}";
        label.fill = am4core.color(matchingGrade.color);

        /**
         * Label 2
         */

        var label2 = chart.radarContainer.createChild(am4core.Label);
        label2.isMeasured = false;
        label2.fontSize = "2em";
        label2.horizontalCenter = "middle";
        label2.verticalCenter = "bottom";
        label2.text = matchingGrade.title.toUpperCase();
        label2.fill = am4core.color(matchingGrade.color);

        /**
         * Hand
         */

        hand = chart.hands.push(new am4charts.ClockHand());
        hand.axis = axis2;
        hand.innerRadius = am4core.percent(55);
        hand.startWidth = 8;
        hand.pin.disabled = true;
        hand.value = props.score;
        hand.fill = am4core.color("#444");
        hand.stroke = am4core.color("#000");

        hand.events.on("positionchanged", () => {
          label.text = axis2.positionToValue(hand.currentPosition).toFixed(1);
          var value2 = axis.positionToValue(hand.currentPosition);
          var matchingGrade = lookUpGrade(axis.positionToValue(hand.currentPosition), props.gradingData);
          label2.text = matchingGrade.title.toUpperCase();
          label2.fill = am4core.color(matchingGrade.color);
          label2.stroke = am4core.color(matchingGrade.color);
          label.fill = am4core.color(matchingGrade.color);
        });
      });
    };

    onMounted(() => {
      init();
    });

    onUnmounted(() => {
      chart.dispose();
    });

    const compScore = computed(() => {
      return score;
    });

    const updateHand = (score) => {
      if (hand && chart) {
        hand.showValue(score, 1000, am4core.ease.cubicOut);
      }
    };

    watchEffect(() => updateHand(props.score));

    return {
      containerId
    };
  }
};
</script>
