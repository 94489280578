import { provide, inject } from 'vue';
import permissions from './permissions';

const PermissionsSymbol = Symbol();

export const createPermissions = store => permissions(store);

export const providePermissions = store => {
	const permissions = createPermissions(store);
	provide(PermissionsSymbol, permissions)
}

export const usePermissions = () => {
	const permissions = inject(PermissionsSymbol)
	if (!permissions) throw new Error("Permissions core is missing");
	return permissions;
}
