<template>
	<div class="ma-filter-wrapper">
		<i @click="toggleFilter" :class="`fas fa-${showFilter ? 'eye-slash' : 'filter'}`" title="Dölj filter"></i>
		<div class="ma-filter" v-show="showFilter">
				<GlobSpinner :isLoading="loading" :isTopRight="true"/>
				<GlobRow>
					<GlobCol v-if="showHistoryToggle" :medium="1">
						<label>Historik</label>
						<br/>
						<GlobToggle
							v-model="historyToogle"
							:toggles="{on: 'På', off: 'Av'}"
							:width="90"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="showOrdernumberToogle" :medium="1">
						<label>Typ</label>
						<br/>
						<GlobToggle
							v-model="ordernumberToogle"
							:toggles="{on: 'Order', off: 'Orderrad'}"
							:width="90"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="assemblyToggle" :medium="1">
						<label>Typ</label>
						<br/>
						<GlobToggle
							v-model="articleToggle"
							:toggles="{on: 'Assembly', off: 'Part'}"
							:width="110"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="showDeductedToggle" :medium="1" style="margin-bottom: .3rem; min-width: 300px;" :style="inputMinWidth ? 'min-width:'+inputMinWidth : ''">
						<label>Typ</label>
						<br/>
						<GlobToggle
							v-model="deductedToggle"
							:toggles="{off: 'visa alla', on: 'Endast uttag'}"
							:width="110"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="showArchiveToggle" :medium="1">
						<label> {{languageSupport || languageSupportCustomField ? $t('general.button.view_archived') : 'Visa arkiverade'}}</label>
						<br/>
						<GlobToggle
							v-model="archiveToggle"
							:toggles="{on: languageSupport || languageSupportCustomField ? $t('general.Yes') : 'Ja', off: languageSupport || languageSupportCustomField ? $t('general.No') : 'Nej'}"
							:width="110"
							@input="fetchFilters"
						/>
					</GlobCol>
					<GlobCol v-if="dateToggle" :medium="1">
						<label>Jämför</label>
						<br/>
						<GlobToggle
							v-model="compareToggle"
							:toggles="{on: 'Revision', off: 'Datum'}"
							:width="110"
						/>
					</GlobCol>
					<GlobCol v-if="materialPlanToggle" :medium="3">
						<label>Inkludera parter i andra planer</label>
						<br/>
						<GlobToggle
							v-model="materialPlanToggleValue"
							:toggles="{on: 'Nej', off: 'Ja'}"
							:width="80"
						/>
					</GlobCol>
					<GlobCol v-if="materialPlanSelectToggle" :medium="2">
						<label>Materialplanerad</label>
						<br/>
						<GlobSelectInput
							v-model="materialPlanSelect"
							:items="[
								{
									value: 'NOT_PLANNED',
									name: 'Ej planerad',
								},
								{
									value: 'PLANNED_FROM_STOCK',
									name: 'Planerad från lager',
								},
								{
									value: 'PLANNED_FROM_MRP',
									name: 'Planerad från nettobehov',
								},
								{
									value: 'DELIVERY',
									name: 'Planerad från inköp',
								}
							]"
							:width="80"
						/>
					</GlobCol>
					<GlobCol v-if="!compareToggle && useDate" :medium="2" style="margin-bottom: .3rem; min-width: 300px;" :style="inputMinWidth ? 'min-width:'+inputMinWidth : ''">
						<label>{{languageSupport || languageSupportCustomField ? $t('general.button.date_range') : 'Datumspan'}}</label>
						<GlobDatePicker @setDates="getDates" @clear="setDates"/>
					</GlobCol>
					<GlobCol v-if="compareToggle" :medium="1">
						<label>Från revision</label>
						<model-select
						:options="revisions"
						v-model="oldRevision"
						placeholder="Revision"
					></model-select>
					</GlobCol>
					<GlobCol v-if="compareToggle" :medium="1">
						<label>Till revision</label>
						<model-select
						:options="revisions"
						v-model="currentRevision"
						placeholder="Revision"
					></model-select>
					</GlobCol>

					<GlobCol v-if="showChange" :medium="2">
						<label>Typ av ändring</label>
						<br/>
						<GlobSelectInput
							v-model="changeSelect"
							:items="[
								{
									value: 'Changed',
									name: 'Förändrad',
								},
								{
									value: 'Added',
									name: 'Tillagd',
								},
								{
									value: 'Deleted',
									name: 'Borttagen',
								}
							]"
							:width="80"
						/>
					</GlobCol>


				</GlobRow>
				<GlobRow>
					<GlobCol :medium="10">
						<GlobRow>
				
						<GlobCol v-for="(filter, index) in rawFilters" :key="filter.key + index + timeStamp" :medium="2" :small="12" style="margin-bottom: .3rem; min-width: 300px;" :style="inputMinWidth ? 'min-width:'+inputMinWidth : ''">
							<label style="width: 100%;">
								{{languageSupport ? $t('general.fields.'+filter.label) : (languageSupportCustomField ? $t(filter.label) : filter.label)}}
								<template v-if="filter.type == 'toggle'">
									<br/>
									<GlobToggle
										v-model="toggleFilterModels[filter.key]"
										:disabled="availableFilters[filter.key] || loading"
										:toggles="{on: 'Nej', off: 'Ja'}"
										:width="80"
										@input="(val) => toggleFilterUpdated(val, filter.key)"
									/>
								</template>	
								<template v-else>
									<GlobDynamicTypeInput
										class="ips-dyamic-type-input-full-width"
										style="width: 100%;"
										:type="getFilterInputType(filter)"
										:data="filter.values"
										:ignoreValueWatch="false"
										:placeholder="`${languageSupport || languageSupportCustomField ? $t('general.search') : 'Sök '+filter.label}`"
										:disabled="availableFiltersCheck[filter.key] || loading"
										v-model="availableFilters[filter.key]"
										@clear="fetchFilters"
										@input="(val) => fetchFilters(val, filter.key)"
									/>
								</template>	
							</label>
							<label v-if="allowNullSearch"> 
							<input type="checkbox" v-model="availableFiltersCheck[filter.key]" value="true">
							Utan {{filter.label}}
							</label>
						</GlobCol>
						</GlobRow>
					</GlobCol>
					<GlobCol :medium="12" style="display: flex; flex-flow: column; justify-content: flex-end; align-items: flex-end;">
						<div style="min-width: 200px;">
							<button class="button button--small" :disabled="filterValidation || loading || disableFilterButton" @click="sendFilter">{{languageSupport || languageSupportCustomField ? $t('general.button.filter') : 'Filtrera'}}</button>
							<button class="button button--small button--outline" @click="resetFilters">{{languageSupport || languageSupportCustomField ? $t('general.button.reset') : 'Återställ'}}</button>
							<slot/>
						</div>

					</GlobCol>
				</GlobRow>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
export default {
	props: {
		enable: {
			type: Boolean,
			default: true,
			required: false
		},
		endpoint: {
			type: String,
			required: true
		},
		dateToggle: {
			type: Boolean,
			required: false,
			default: true
		},
		multiselect: {
			type: Boolean,
			required: false,
			default: false
		},
		showChange: {
			type: Boolean,
			required: false,
			default: false,
		},
		useDate: {
			type: Boolean,
			required: false,
			default: true
		},
		defaultDate: {
			type: Object,
			required: false
		},
		showArchiveToggle: {
			type: Boolean,
			required: false,
			default: false
		},
		showOrdernumberToogle: {
			type: Boolean,
			required: false,
			default: false
		},
		assemblyToggle: {
			type: Boolean,
			required: false,
			default: true
		},
		materialPlanSelectToggle: {
			type: Boolean,
			required: false,
			default: false,
		},
		materialPlanToggle: {
			type: Boolean,
			required: false,
			default: false,
		},
		extraFilters: {
			type: Array,
			required: false
		},
		template: {
			type: Object,
			required: false
		},
		viewName: {
			type: String,
			required: false
		},
		allowNullSearch: {
			type: Boolean,
			required: false
		},
		enableByDefault: {
			required: false,
			default: true
		},
		showDeductedToggle: {
			type: Boolean,
			required: false,
			default: false
		},
		showHistoryToggle: {
			type: Boolean,
			required: false,
			default: false
		},
		preselectedValues: {
			type: Array,
			required: false
		},
		disableFilterButton: {
			 type: Boolean,
			 required: false,
			 default: false
		},
		languageSupport: {
			 type: Boolean,
			 required: false,
			 default: false
		},
		languageSupportCustomField: {
			 type: Boolean,
			 required: false,
			 default: false
		},
		triggerFilter: {
			type: Boolean,
		},
		inputMinWidth: {
			default: null,
		},
		
	},
	data() {
		return {
			showFilter: this.enableByDefault,
			requestSkeleton: {
				fromDate: '',
				toDate: '',
				page: 0,
			},
			fields: {},
			rawFilters: [],
			archiveToggle: false,
			availableFilters: [],
			availableFiltersCheck: {},
			articleToggle: true,
			ordernumberToogle:true,
			deductedToggle:true,
			compareToggle: false,
			materialPlanToggleValue: false,
			materialPlanSelect: null,
			timeStamp: new Date().getTime() / 1000,
			requestType: 'orderdetail',
			compareType: 'date',
			currentRevision: '',
			changeSelect: null,
			oldRevision: '',
			loading: false,
			revisions: [], //MOCKDATA
			project: '',
			historyToogle: false,
			toggleFilterModels: {
				'IncludeDelivered': true,
				'ShowAllArticles': true
			},
			toggleFilterModelInitData: {}
		}
	},
	mounted() {
		this.init();
		this.toggleFilterModelInitData = _.cloneDeep(this.toggleFilterModels);
	},
	activated() {
		this.availableFilters = {};
		setTimeout(() => this.init(), 10);
	},
	methods: {
		toggleFilterUpdated(val, key){
			this.availableFilters[key] = [val ? '1' : '0'];
			// console.log('availableFilters', this.availableFilters)
			this.fetchFilters();
		},
		toggleFilter() {
			this.showFilter = !this.showFilter;
			// console.log(this.showFilter)
		},
		async init() {
			this.setDates();
			await this.fetchFilters();
		},
		getFilterInputType(filter){
			if(filter.type == 'date_less_than'){
				return 'date';
			}

			return this.multiselect ? 'virtualsearchmulti' : 'virtualsearch';
		},
		sendFilter(isReset = false) {
			this.$emit('apply', {
				body: this.computedRequest,
				filters: this.rawFilters,
				toggles: {
					orderToggle: this.ordernumberToogle,
					assembly: this.articleToggle,
					archive: this.archiveToggle,
					isDeducted: this.deductedToggle,
					history: this.historyToogle,
					
				},
				isReset: isReset === true ? true : false,
			})
		},
		async applyTemplate(values) {
			if (values) {
				Object.keys(values).map(i => {
					this[i] = values[i]
				})
			}
			await this.fetchFilters();
			this.sendFilter();
		},
		getCategoryName(key) {
			return this.fields[key]
		},
		setDates() {
			this.requestSkeleton.fromDate = this.defaultDate ? this.defaultDate.fromDate : null /* this.$time.format(this.$time.back(24)) */;
			this.requestSkeleton.toDate = this.defaultDate ? this.defaultDate.toDate : null;
			/* TODO: Set date to 14 days back */
		},
		getDates(dates) {
			this.requestSkeleton.fromDate = dates.startDate;
			this.requestSkeleton.toDate = dates.endDate;
			this.fetchFilters();
		},
		clearRevisions() {
			this.revisionRequest.currentRevision = ''
			this.revisionRequest.oldRevision = ''
			this.fetchFilters()
		},
		createObjectFromFilters(data) {
			return data
			.reduce((acc, curr) => {
				acc[curr.key] = curr.currentValue || '';
				return acc
			}, {})
		},
		createObjectFromFields(data) {
			return data
			.reduce((acc, curr) => {
				acc[curr.Alias] = curr.Value
				return acc
			}, {})
		},
		async fetchFilters(values, key) {
			if (this.loading) {
				return true;
			}
			this.loading = true;
			await this.$http(this.endpoint, {...this.computedRequest, limit: 1, useFull: 1})
			.then(res => {
				let parsed = typeof res == 'string' ? JSON.parse(res) : res;
				this.rawFilters = parsed.filters.map((filter) => {
					if(filter.values && Array.isArray(filter.values)){
						var index = filter.values.findIndex(x => x == 'Välj alla');
						if(index != undefined && index != -1){
							filter.values.unshift(this.$t('general.select_all'));
						}
					}
					return filter;
				});
				this.availableFilters = this.createObjectFromFilters(parsed.filters)
				this.fields = this.createObjectFromFields(parsed.fields)
				this.revisions = parsed.currentMaxRevision ? Array.from(Array(parsed.currentMaxRevision+1).keys()).map(i => { return {value: i,  text: i} }) : []
				this.$emit('updated', {
					body: this.computedRequest,
					filters: this.rawFilters,
					toggles: {
						orderToggle: this.ordernumberToogle,
						assembly: this.articleToggle,
						archive: this.archiveToggle,
						history: this.historyToogle,
					}
				})
				this.loading = false;
				return true;
			},
			() => {
				this.loading = false;
			})
		},
		async resetFilters() {
			this.computedRequest = {
				compareToggle: false,
				availableFilters: {},
				oldRevision : '',
				currentRevision: '',
				changeSelect: null,
				materialPlanSelect: null,
				materialPlanToggleValue: false,
				articleToggle: true,
				ordernumberToogle:true,
				archiveToggle: false,
				historyToogle: false,
			}
			this.toggleFilterModels = this.toggleFilterModelInitData;
			await this.fetchFilters();
			this.timeStamp = new Date().getTime() / 1000;
			this.sendFilter(true);
		}
	},
	computed: {
		computedRequest: {
			get() {
				return this.compareMode == 'revision' ? {
					...this.computedRequestSkeleton,
					type: this.assemblyToggle ? this.articleMode : undefined,
					onlyDeducted: this.showDeductedToggle ? this.deductedToggle : undefined,
					showOrdernumber: this.showOrdernumberToogle ? this.ordernumberToogle : undefined,
					showArchived: this.showArchiveToggle ? (this.archiveToggle ? 1 : 0) : undefined,
					showHistory: this.showHistoryToggle ? this.historyToogle : undefined,
					compareType: this.dateToggle ? this.compareMode : undefined,
					filters: this.getFilters,
					currentRevision: this.currentRevision,
					ViewName: this.viewName,
					oldRevision: this.oldRevision,
					stockStatus: this.materialPlanSelect && this.materialPlanSelect.value != 'Välj' ? this.materialPlanSelect.value : undefined,
				} : {
					...this.computedRequestSkeleton,
					type: this.assemblyToggle ? this.articleMode : undefined,
					showOrdernumber: this.showOrdernumberToogle ? this.ordernumberToogle : undefined,
					onlyDeducted: this.showDeductedToggle ? this.deductedToggle : undefined,
					showArchived: this.showArchiveToggle ? (this.archiveToggle ? 1 : 0) : undefined,
					showHistory: this.showHistoryToggle ? this.historyToogle : undefined,
					compareType: this.dateToggle ? this.compareMode : undefined,
					filters: this.getFilters,
					ViewName: this.viewName,
					stockStatus: this.materialPlanSelect && this.materialPlanSelect.value != 'Välj' ? this.materialPlanSelect.value : undefined,
				}
			},
			set(newValue) {
				this.compareToggle = newValue.compareToggle
				this.availableFilters = newValue.availableFilters,
				this.oldRevision = newValue.oldRevision,
				this.currentRevision = newValue.currentRevision
				this.articleToggle = newValue.articleToggle
				this.ordernumberToogle= newValue.ordernumberToogle
				this.archiveToggle = newValue.archiveToggle
				this.historyToogle = newValue.historyToogle
				this.materialPlanSelect = newValue.materialPlanSelect;
				this.materialPlanToggleValue = newValue.materialPlanToggleValue;
				this.changeSelect = newValue.changeSelect;
			}
		},
		getFilters() {
			const extra = this.extraFilters;
			const filterObject = Object.keys(this.availableFilters)
				.filter(item => {
					return (this.availableFilters[item] && this.availableFilters[item].length > 0) || this.availableFiltersCheck[item]
				})
				.reduce((acc, curr) => {
					acc[curr] = !this.availableFiltersCheck[curr] ? this.availableFilters[curr] : null
					return acc
				}, {})
				extra && extra.forEach(item => {
					filterObject[Object.entries(item)[0][0]] = Object.entries(item)[0][1]
				})
				if (this.materialPlanToggleValue) {
					filterObject['MaterialPlanId'] = 'null';
				}
				if (this.changeSelect && this.changeSelect.value !== 'Välj') {
					filterObject[this.changeSelect.value] = 1;
				}
			return filterObject;
		},
		computedRequestSkeleton() {
			return Object.keys(this.requestSkeleton).reduce((acc, curr)=> {
				if (this.requestSkeleton[curr]) {
					acc[curr] = this.requestSkeleton[curr]
				}
				return acc
			}, {})
		},
		articleMode() {
			return this.articleToggle ? 'order' : 'orderdetail';
		},
		compareMode() {
			return this.compareToggle ? 'revision' : 'date'
		},
		checkInput() {
			return this.oldRevision !== '' && this.currentRevision !== ''
		},
		filterValidation() {
			return this.compareToggle && !this.checkInput && !this.enable
		}
	},
	watch: {
		triggerFilter(){
			this.sendFilter();
		},
		template(newVal, oldVal) {
			this.applyTemplate(newVal)
		},
		async extraFilters(newVal, oldVal) {
			await this.fetchFilters()
		},
		preselectedValues() {
		  const self = this;
		  this.preselectedValues.forEach(function (item) {
		    //Check whether the Filter field exists or not
		    const filterField = self.rawFilters.find(d => d.key === item.key)
		    if (filterField) {
		      //Get the existing filtered values
		      let filteredValues = self.availableFilters[item.key];
		      if (typeof filterField.values == "object") {
		        filteredValues = filteredValues ? filteredValues : [];
		        //Loop through the selected values
		        item.values.forEach(function (value) {
		          //Make the value select if its exists in the dropdown list of filter values
		          if (filterField.values.includes(value)) {
		            filteredValues.push(value);
		          }
		        })
		        filteredValues = [...new Set(filteredValues)];
		      } else {
		        filteredValues = item.values;
		      }
		      self.availableFilters[item.key] = filteredValues;
		    }
		  })
		}
	}
}
</script>

<style lang="scss" scoped>
	.button {
		margin-bottom: 1.5rem;
	}
	.ma-filter-wrapper {
		padding: 1rem 0;
		position: relative;
		> i {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
</style>

