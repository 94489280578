<template>
  <Wrapper :name="containerId" :rawName="name" :xPanel="xPanel" :height="height" />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Wrapper from "./molecules/Wrapper.vue";
export default {
  components: {
    Wrapper
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    events: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    xPanel: {
      required: false,
      default: true,
      type: Boolean
    },
    height: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      chart: null
    };
  },
  unmounted() {
    this.chart.dispose();
  },
  computed: {
    containerId() {
      return this.$utils.getIdFromName(this.name);
    },
    dataSet() {
      const colorSet = new am4core.ColorSet();
      colorSet.saturation = 0.5;
      return this.data.map((item, index) => {
        return {
          ...item,
          color: colorSet.getIndex(index)
        };
      });
    }
  },
  methods: {
    init() {
      am4core.ready(() => {
        am4core.useTheme(am4themes_animated);

        this.chart = am4core.create(this.containerId, am4plugins_timeline.SerpentineChart);
				this.chart.svgContainer.autoResize = false;
        this.chart.curveContainer.padding(50, 20, 50, 20);
        this.chart.levelCount = 4;
        this.chart.yAxisRadius = am4core.percent(25);
        this.chart.yAxisInnerRadius = am4core.percent(-25);
        this.chart.maskBullets = false;

        this.chart.data = this.dataSet;

        this.chart.dateFormatter.dateFormat = "yyyy-MM-dd";
        this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
        this.chart.fontSize = 11;

        var categoryAxis = this.chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.paddingRight = 25;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.innerRadius = -60;
        categoryAxis.renderer.radius = 60;

        var dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 70;
        dateAxis.baseInterval = { count: 10, timeUnit: "minute" };
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.startLocation = -0.5;
        dateAxis.renderer.line.strokeDasharray = "1,4";
        dateAxis.renderer.line.strokeOpacity = 0.6;
        dateAxis.tooltip.background.fillOpacity = 0.2;
        dateAxis.tooltip.background.cornerRadius = 5;
        dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        dateAxis.tooltip.label.paddingTop = 7;

        var labelTemplate = dateAxis.renderer.labels.template;
        labelTemplate.verticalCenter = "middle";
        labelTemplate.fillOpacity = 0.7;
        labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
        labelTemplate.background.fillOpacity = 1;
        labelTemplate.padding(7, 7, 7, 7);

        var series = this.chart.series.push(new am4plugins_timeline.CurveColumnSeries());
        series.columns.template.height = am4core.percent(20);
        series.columns.template.tooltipText = "{task}: [bold]{openDateX}[/] - [bold]{dateX}[/]";

        series.dataFields.openDateX = "start";
        series.dataFields.dateX = "end";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "color"; // get color from data
        series.columns.template.propertyFields.stroke = "color";
        series.columns.template.strokeOpacity = 0;

        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 3;
        bullet.circle.strokeOpacity = 0;
        bullet.propertyFields.fill = "color";
        bullet.locationX = 0;

        var bullet2 = series.bullets.push(new am4charts.CircleBullet());
        bullet2.circle.radius = 3;
        bullet2.circle.strokeOpacity = 0;
        bullet2.propertyFields.fill = "color";
        bullet2.locationX = 1;

        var imageBullet1 = series.bullets.push(new am4plugins_bullets.PinBullet());
        imageBullet1.disabled = true;
        imageBullet1.propertyFields.disabled = "disabled1";
        imageBullet1.locationX = 1;
        imageBullet1.circle.radius = 20;
        imageBullet1.propertyFields.stroke = "color";
        imageBullet1.background.propertyFields.fill = "color";
        imageBullet1.image = new am4core.Image();
        imageBullet1.image.propertyFields.href = "image1";

        var imageBullet2 = series.bullets.push(new am4plugins_bullets.PinBullet());
        imageBullet2.disabled = true;
        imageBullet2.propertyFields.disabled = "disabled2";
        imageBullet2.locationX = 0;
        imageBullet2.circle.radius = 20;
        imageBullet2.propertyFields.stroke = "color";
        imageBullet2.background.propertyFields.fill = "color";
        imageBullet2.image = new am4core.Image();
        imageBullet2.image.propertyFields.href = "image2";

        var eventSeries = this.chart.series.push(new am4plugins_timeline.CurveLineSeries());
        eventSeries.dataFields.dateX = "eventDate";
        eventSeries.dataFields.categoryY = "category";
        eventSeries.data = this.events;
        eventSeries.strokeOpacity = 0;

        var flagBullet = eventSeries.bullets.push(new am4plugins_bullets.FlagBullet());
        flagBullet.label.propertyFields.text = "letter";
        flagBullet.locationX = 0;
        flagBullet.tooltipText = "{description}";

        this.chart.scrollbarX = new am4core.Scrollbar();
        this.chart.scrollbarX.align = "center";
        this.chart.scrollbarX.width = am4core.percent(85);

        var cursor = new am4plugins_timeline.CurveCursor();
        this.chart.cursor = cursor;
        cursor.xAxis = dateAxis;
        cursor.yAxis = categoryAxis;
        cursor.lineY.disabled = true;
        cursor.lineX.strokeDasharray = "1,4";
        cursor.lineX.strokeOpacity = 1;

        dateAxis.renderer.tooltipLocation2 = 0;
        categoryAxis.cursorTooltipEnabled = false;
      }); // end am4core.ready()
    }
  },
  mounted() {
    this.init();
  }
};
</script>
