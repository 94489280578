class Item {
	constructor(config, data) {
		this.el = config.element;
		this.data = data;
		this.startCallback = config.startCallback;
		this.endCallback = config.endCallback;
	}

	onDragStart(e) {
		const isFunction = func => typeof func == 'function';
		e.dataTransfer.setData('dropData', JSON.stringify(isFunction(this.data) ? this.data() : this.data));
		this.el.classList.add('ips-draggable__item--active')
		if (typeof this.startCallback == 'function') {
			this.startCallback();
		}
	}
	onDragEnd(e) {
		this.el.classList.remove('ips-draggable__item--active')
		if (typeof this.endCallback == 'function') {
			this.endCallback();
		}
	}

	init() {
		this.el.classList.add('ips-draggable__item')
		this.el.setAttribute('draggable', true);
		this.el.addEventListener('dragstart', (e) => this.onDragStart(e));
		this.el.addEventListener('dragend', (e) => this.onDragEnd(e));
	}
}

export const draggable = {
	beforeMount(el, options) {
		const { data, startCallback, endCallback } = options.value;
		const item = new Item({
			element: el,
			startCallback: startCallback ? startCallback : null,
			endCallback: endCallback ? endCallback : null,
		}, data);
		item.init();
	}
}