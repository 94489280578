class Dropzone {
	constructor(config, callback) {
		this.el = config.element;
		this.callback = callback;
	}

	onDrop(e) {
		this.el.classList.remove('ips-draggable__dropzone--active')
		const data = e.dataTransfer.getData('dropData');
		const pos = this.getPositionInDropzone(e);
		this.callback(JSON.parse(data), e, pos);
	}

	getPositionInDropzone(event) {
		const dz = this.el.getBoundingClientRect();
		return {
			x: event.clientX - dz.left,
			y: event.clientY - dz.top
		}
	}

	onDragOver(e) {
		e.preventDefault();
		this.el.classList.add('ips-draggable__dropzone--active')
	}

	onDragLeave(e) {
		e.preventDefault();
		this.el.classList.remove('ips-draggable__dropzone--active')
	}

	init() {
		this.el.classList.add('ips-draggable__dropzone')
		this.el.addEventListener('dragover', (e) => this.onDragOver(e));
		this.el.addEventListener('dragleave', (e) => this.onDragLeave(e));
		this.el.addEventListener('drop', (e) => this.onDrop(e));
	}
}

export const dropzone = {
	beforeMount(el, options) {
		const { callback } = options.value;
		const dropzone = new Dropzone({
			element: el
		}, (data, e, pos) => callback(data, e, pos));
		dropzone.init();
	}
}