<template>
	<div class="mp-table__head mp-table__head--summary">
		<div v-if="extraCell" class="mp-table__cell" style="position: sticky; left: -1px; z-index: 19; min-width: 36px;">
		</div>
		<div 
			v-for="item in head"
			:key="item.Alias + item.Value"
			class="mp-table__cell mp-table__cell--head"
			:style="`min-width: ${columnConfig.widths[item.Alias] * 8 + columnConfig.multiplier}px; min-height: 35px`"
			>
			{{itemList[item.Alias]}}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		head: {
			type: Array,
			required: true
		},
		body: {
			type: Array,
			required: true
		},
		config: {
			type: Object,
			required: true
		},
		columnConfig: {
			type: Object,
			required: true
		},
		extraCell: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	methods: {
		renderSummary(item) {
			const key = item.Alias;
			const config = this.config[key]
			if (config && this.summarize[config.summary]) {
				return this.summarize[config.summary](key)
			} else {
				return '\r'
			}
		},
		computeItemList() {
			this.itemList = this.head.reduce((acc, curr) => {
				acc[curr.Alias] = this.renderSummary(curr)
				return acc
			}, {})
		},
		round(value, decimals) {
			return typeof value === "number" ? Number(Math.round(value+'e'+decimals)+'e-'+decimals) : '';
		},
	},
	computed: {
		summarize() {
			return {
				SUM: (key) => {
					return this.body.reduce((acc, curr) => {
						return this.round(acc + parseFloat(curr[key]), 2)
					}, 0)
				},
				COUNT: (key) => {
					return this.body.reduce((acc, curr) => {
						return acc + 1
					}, 0)
				}
			}
		},
		itemList() {
			return this.head.reduce((acc, curr) => {
				acc[curr.Alias] = this.renderSummary(curr)
				return acc
			}, {})
		}
	}
}
</script>
<style lang="scss">
.mp-table {
	&__head {
		&--summary {
			.mp-table__cell {

			}
		}
	}
}
</style>