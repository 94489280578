<template>
		<tr class="headings">
			<th class="summary column-title" v-if="extraCell"></th>
			<th class="summary column-title" v-for="item in head" :key="'summary-'+item.Alias">{{round(getSummary[item.Alias], 2)}}</th>
			<th class="summary column-title" v-for="n in extraCellAfter.length" :key="'summary-extracell-'+n"></th>
		</tr>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			required: true
		},
		head: {
			type: Array,
			required: true
		},
		extraCell: {
			type: Boolean,
			required: false,
			default: false
		},
		extraCellAfter: {
			type: [Array, Boolean],
			required: false,
			default: false
		}
	},
	data() {
		return {
			data: []
		}
	},
	mounted() {
	},
	methods: {
		round(value, decimals) {
			return typeof value === "number" ? Number(Math.round(value+'e'+decimals)+'e-'+decimals) : '';
		}
	},
	computed: {
		getSummary() {
			return this.items.reduce((acc, curr) =>{
				Object.keys(curr).forEach(item => {
					if (!acc[item]) {
						acc[item] = typeof curr[item] !== "string" ? curr[item] : ''
					} else {
						acc[item] += typeof curr[item] !== "string" ? curr[item] : ''
					}
				});
				return acc
			}, {})
		}
	}
}
</script>

<style lang="scss" scoped>
.summary {
	color: white;
	position: sticky;
	bottom: -1px;
	background-color: rgba(52, 73, 94, 1);
	z-index: 2;
	vertical-align: center;
	padding-top: .5rem;
	padding-bottom: .5rem;
}
</style>