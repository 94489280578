<template>
	<div :class="`cell medium-${medium} small-${small || medium} large-${large || medium}`">
		<slot/>
	</div>
</template>

<script>
export default {
	props: ['medium', 'small', 'large']
}
</script>