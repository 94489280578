export const dropdownBody = {

	props: ['placeholder', 'data', 'value', 'disabled', 'ignoreValueWatch'],
	data() {
		return {
			isSearching: false,
			inputString: '',
			isMulti: false,
		}
	},
	methods: {
		setValue(item) {
			this.$emit('input', item)
			this.isSearching = false;
			this.inputString = item
		},
		clear() {
			this.$emit('input', '')
			this.$emit('clear')
			this.inputString = ''
		},
		focus() {
			this.isSearching = true;
		},
		blur(e) {
			setTimeout(() => {
				this.isSearching = false;
			}, 400);
		}
	},
	computed: {
		active(){
			return this.inputString.length > 0 && !this.data.includes(this.inputString) && this.filterData.length > 0
		},
		showDropDown() {
			return this.isSearching || this.active
		},
		filterData() {
			const pattern = new RegExp(this.inputString, 'gi');
			return this.inputString ? this.data.filter(i => i.match(pattern)) || ['Inga resultat'] : this.data
		}
	},
	watch: {
		value(val) {
			if (!this.isMulti && !this.ignoreValueWatch) {
				this.inputString = val
			}
		}
	}
}

export const dropdownSearchMethods = {
	methods: {
		filterArrayByString(data, filterValue) {
			const pattern = new RegExp(filterValue, 'gi');
			return filterValue ? data.filter(i => i.match(pattern)) || ['Inga resultat'] : data
		}
	}
}