import { ref } from 'vue';
export const props = {
  items: {
    type: Object,
    required: true
  },
  itemRenderFunctions: {
    type: [Object, Boolean],
    required: false,
    default: false
	},
	enableRenderFunctions: {
		type: Boolean,
		required: false
	},
  maxHeight: {
    required: true
  },
  bulkactions: {
    type: [Object, Boolean],
    required: false
  },
  isDraggable: {
    type: Boolean,
    required: false
  },
  clickRow: {
    type: [Function, Boolean],
    required: false,
    default: false
  },
  actions: {
    type: [Array, Boolean],
    required: false
  },
  diffDataKey: {
    type: String,
    required: false
  },
  setItemSize: {
    type: Number,
    required: false
  },
  useButtons: {
    type: Boolean,
		required: false,
		default: false
  },
  enableDownload: {
    type: Boolean,
    required: false
  },
  disableSummary: {
    type: Boolean,
    required: false,
    default: false
  },
  sortable: {
    type: Boolean,
    required: false,
    default: false
  },
  isTouch: {
    type: Boolean,
    required: false,
    default: false
  }
};

export const useItemRenderer = (renderFunctions) => {
  return {
    render(item, key, diffDataKey) {
      const meth = renderFunctions ? renderFunctions[key] : false;
      if (!diffDataKey) {
        return meth ? meth(item, key) : item[key];
      } else {
        const diffItem = item[diffDataKey] ? JSON.parse(item[diffDataKey]) : [{}];
        if (diffItem[0][key] !== undefined && diffItem[0][key] !== null && item[key] != diffItem[0][key]) {
          return `
          <span style="color: green;">
            ${meth ? meth(item, key) : item[key]}
          </span>
          <span style="color: red;">
            ${meth ? meth(diffItem[0], key) : diffItem[0][key]}
          </span>
          `;
        } else {
          return meth ? meth(item, key) : item[key];
        }
      }
    }
  };
};

export const sorting = (a, b) => {
  if (typeof a[key] !== typeof b[key]) {
    return;
  }
  if (typeof a[key] == "string") {
    const nameA = a[key];
    const nameB = b[key];
    if (descending.value) {
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    } else {
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    }
  } else {
    if (descending.value) {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    } else {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    }
  }
}

export const useBulkactions = (bulkactions, selected, useButtons) => {
  return {
    bulkaction: ref(''),
    func: ref(false),
    async execute(action){
     if (action && useButtons.value) {
       this.func.value = bulkactions.functions ? bulkactions.functions[action] : false;
      } else {
        this.func.value = bulkactions.functions ? bulkactions.functions[this.bulkaction.value] : false;
      }
      const ret = this.func ? await func(selected.value) : null;
      if (ret) {
        selected.value = [];
      }
    }
  }
}