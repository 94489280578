import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
	'./contentTypes', false, /[\w-]+\.js$/
)

export const contentTypes = requireComponent.keys().map(fileName => {
	const componentConfig = requireComponent(fileName)
	return {name: componentConfig.default.name, config: componentConfig.default}
})

export const cruds = contentTypes.map(i => {
	return {
		name: i.name,
		slug: i.config.slug,
		config: i.config,
		hide: i.config.hide,
		isCustom : i.config.isCustom,
		access: i.config.access
	}
})

export const routes = [
	{
		name: "general.home",
		key: 'homeContent',
		icon: "home",
		route: '/admin',
		languageSupport: true
	},
	/* {
		name: "Administrera",
		key: 'editContent',
		icon: "pen",
		children: cruds
	}, */
]
