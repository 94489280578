import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export const registerComponents = (app, components) => {
	components.forEach(group => {
		group.keys().forEach(fileName => {
			const componentConfig = group(fileName)
			const componentName = upperFirst(
				camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
			)
			app.component(componentName, componentConfig.default || componentConfig)
		})
	})
}