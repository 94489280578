import BaseModule from './BaseModule';

export class Devices extends BaseModule {  

    state() {
        return {
            loaded:false,
            data: {
                head:[[
                    {
                        "Alias": "id",
                        "Value": "Id"
                    },
                    {
                        "Alias": "Name",
                        "Value": "Namn"
                    },
                    {
                        "Alias": "ShelfAmount",
                        "Value": "Antal hyllor"
                    },
                    {
                        "Alias": "location",
                        "Value": "placering"
                    },
                    {
                        "Alias": "Description",
                        "Value": "Beskrivning"
                    }
                ]],
                body:[]
            },
            singleDevice: {},
            todos: [
                { id: 1, text: '...', done: true },
                { id: 2, text: '...', done: false }
            ]
        }
    }

    getters() {
        return {
            doneTodos: state => { //for searching or filtering
                return state.todos.filter(todo => todo.done)
            }
        }
    }

    actions = () => {
        return {
            getDevices : async ({commit}) => {
                commit('setLoaded',false)
                try {
                    // let devices = await this.get('/admin/devices');
                    commit('setDevices', ['test','test']);//devices
                } catch (error) {
                    console.log(error)
                }
                commit('setLoaded',true)
                
            },

            getDevice : async ({commit},id) => {
                console.log(id);
                commit('setLoaded',false)
                try {
                    // let device = await this.get('/admin/device',id);
                    commit('setDevice', {});//device
                } catch (error) {
                    console.log(error)
                }
                commit('setLoaded',true)
            },

            callServerOutOfCrud : async (context) => { //context == {commit, dispatch, getters, state, }
                //constext.dispatch('getDevice');
                //console.log(context.state);
                //await this.php.get('endpoint'); //get,put,delete,post
                //await this.asp.get('endpoint'); //get,put,delete,post
            },
        };
    }

    mutations() {
        return {
            setDevices: (state, devices) => (state.data.body = devices),
            setDevice: (state, device) => (state.singleDevice = device),
            setLoaded: (state,loaded) => (state.loaded = loaded),
        }
    }

    modules(){
        return{
        }
    }

    getModule = () => {
        return {
            //namespaced: true, // https://vuex.vuejs.org/guide/modules.html#namespacing
            state: this.state(),
            getters: this.getters(),
            actions: this.actions(),
            mutations: this.mutations(),
            modules:this.modules()
        }
    }
}