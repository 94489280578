<template>
	<div class="container body">
		<div class="main_container" id="app">
			<div style="display: flex">
				<Navbar v-if="!$route.meta.panelMode && auth" :title="'General'"/>
				<MainView/>
			</div>
		</div>
	</div>
</template>

<script>
import { provideEventBus } from '@/plugins/eventBus';
import { provideApi } from '@/plugins/api';
import { providePermissions } from '@/plugins/permissions';
import { provideNotifications } from '@/plugins/notifications';
import { onMounted, onBeforeMount, ref, computed} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Navbar from './Navbar.vue';
import MainView from './MainView.vue';

export default {
	name: 'App',
	components: {
		Navbar,
		MainView,
	},
	setup() {
		const store = useStore();
		provideEventBus();
		provideApi();
		providePermissions(store);
		provideNotifications(store);
		const auth = computed(() => store.state.AUTHENTICATED);

		const router = useRouter();
		const meta = ref(router.currentRoute.value.meta);

		onMounted(() => {
			store.dispatch('INIT');
		})

		return {
			meta,
			auth
		}
	}
}

</script>