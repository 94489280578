<template>
	<label style="display: inline">
		{{label}} <i v-if="help" class="fas fa-question-circle" :title="help"></i>
		<slot/>
	</label>
</template>

<script>
export default {
	props: ['help','label']
}
</script>

<style scoped>
label{
	display: inline;
    font-weight: 500;
    color: #495057;
}
</style>