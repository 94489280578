import { php, asp } from '../../plugins/api/endpoints';

export default class BaseModule {
  constructor () {
    this.php = php;
    this.asp = asp;
  }

  async get(endpoint,data = null) {
    const res = await php.get(`${endpoint}`, data);
    return res.data;
  }

  async getById(endpoint,id) {
    const res = await php.get(`${endpoint}/${id}`);
    return res.data;
  }

  async update(endpoint,id, data) {
    const res = await php.put(`${endpoint}/${id}`, data);
    return res.data;
  }

  async create(endpoint,data) {
    const res = await php.post(`${endpoint}`, data);
    return res.data;
  }

  async delete(endpoint,id) {
    const res = await php.delete(`${endpoint}/${id}`);
    return res.data;
  }


}
