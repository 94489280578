import {
	renderBooleanFromNumber
} from "./renderUtils";

const LockerActive = (current) => `<i class="fas ${renderBooleanFromNumber(current.LockerActive, 'check green', 'times red')}"></i>`;
const ImagePath = (current) => `<i class="fas fa-${current.ImagePath ? 'image' : ''}"></i>`;
const GroupAmount = (current) => `<i title="${current.GroupAmount > 0 ? 'Artikeln finns i grupp' : ''}" class="fas fa-${current.GroupAmount > 0 ? 'object-group' : ''}"></i>`;

export const useRenderFunctions = () => ({
	LockerActive,
	ImagePath,
	GroupAmount
});
