<template>
  <Wrapper :name="containerId" :rawName="name" :xPanel="xPanel" :height="height" />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Wrapper from "./molecules/Wrapper.vue";
export default {
  components: {
    Wrapper
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    toName: {
      type: String,
      required: false
    },
    fromName: {
      type: String,
      required: false
    },
    valueName: {
      type: String,
      required: false
    },
    xPanel: {
      required: false,
      default: true,
      type: Boolean
    },
    height: {
      type: Number,
      required: false
    }
  },
  computed: {
    containerId() {
      return this.$utils.generateId(this.name);
    }
  },
  data() {
    return {
      chart: null
    };
  },
  unmounted() {
    this.chart.dispose();
  },
  methods: {
    init() {
      am4core.ready(() => {
        // Themes begin
        am4core.useTheme(am4themes_animated);
        // Themes end

        this.chart = am4core.create(this.containerId, am4charts.SankeyDiagram);
        this.chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

        this.chart.data = this.data;

        let hoverState = this.chart.links.template.states.create("hover");
        hoverState.properties.fillOpacity = 0.6;

        this.chart.dataFields.fromName = this.fromName || "from";
        this.chart.dataFields.toName = this.toName || "to";
        this.chart.dataFields.value = this.valueName || "value";

        // for right-most label to fit
        this.chart.paddingRight = 30;

        // make nodes draggable
        var nodeTemplate = this.chart.nodes.template;
        nodeTemplate.inert = true;
        nodeTemplate.readerTitle = "Drag me!";
        nodeTemplate.showSystemTooltip = true;
        nodeTemplate.width = 20;
      }); // end am4core.ready()
    }
  },
  mounted() {
    this.init();
  }
};
</script>
