<template>
  <div class="bulkactions" :class="bulkactions.touch ? 'bulkactions--touch' : ''">
    <p v-if="bulkactions && selected.length > 0">Antal valda ({{ selected.length }})</p>
    <GlobCol :medium="12" v-if="bulkactions && useButtons" style="padding-left: 0; display: flex;">
      <button
        v-for="action in bulkactions.actions"
        :key="action.value + '-ba'"
        :disabled="selected.length <= 0"
        class="button"
        @click="execBulkaction(action.value)"
      >
        {{ action.name }}
      </button>
    </GlobCol>
    <GlobRow>
      <GlobCol :medium="3" v-if="bulkactions && !useButtons" style="padding-left: 0">
        <InputSelect
          :placeholder="'Välj åtgärd'"
          :data="bulkactions.actions ? bulkactions.actions : []"
          v-model="selectedBulkaction"
        />
      </GlobCol>
      <GlobCol :medium="6" v-if="bulkactions && !useButtons">
        <button @click="execBulkaction" :disabled="!selected.length" class="button default">Utför åtgärd</button>
      </GlobCol>
    </GlobRow>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  emits: ["reset-selection"],
  props: {
    bulkactions: {
      type: [Object, Boolean],
      required: false
    },
    useButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    selected: {
      required: true
    }
  },
  setup(props, { emit }) {
    const selectedBulkaction = ref(null);
    const execBulkaction = async (action) => {
      let func = false;
      if (action && props.useButtons) {
        func = props.bulkactions.functions ? props.bulkactions.functions[action] : false;
      } else {
        func = props.bulkactions.functions ? props.bulkactions.functions[selectedBulkaction.value] : false;
      }
      const ret = func ? await func(props.selected) : null;
      if (ret) {
        emit("reset-selection");
      }
    };

    return {
      execBulkaction,
      selectedBulkaction
    };
  }
};
</script>

<style lang="scss" scoped>
.bulkactions {
	font-size: .8rem;
  margin: 1rem 0;
  &--touch {
  }
}

.bulkactions {
  margin: 1rem 0;
}
</style>
