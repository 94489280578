import {
  renderOperatorLogout,
  renderStatusIcon,
  renderProgressIcon,
  renderOperatorIcon,
  setIcon,
  getIcon,
	getStockIcon,
	renderBooleanFromNumber
} from "./renderUtils";

const pcaCity = (current) => `<i title="${
	current.Lat ? "Order redo för transportplanering" : "Koordinater saknas för order"
}" class="fas fa-globe-americas ${current.Lat ? "green" : "red"}"></i> ${current.pcaCity}`

const aIsloggedin = (current) => renderOperatorLogout(current.aIsloggedin || "1")

const oStatusIcon = (current) => `<i title="${current.oStatusIcon}" class="fas ${renderStatusIcon(current.oStatusIcon || "0")}"></i>`

const opAmount = (current) => `<i title="${current.opAmount}" class="fas ${renderOperatorIcon(current.opAmount || 0)}"></i>`

const oStatusPct = (current) => `<i title="${current.oStatusPct}" class="fas ${renderProgressIcon(
	current.oStatusPct || "0"
)}"></i> ${current.oStatusPct * 100}%`

const Deleted = (current) => setIcon(current.Deleted, "fa-minus red", "Borttagen")

const Added = (current) => setIcon(current.Added, "fa-plus green", "Tillagd")

const Changed = (current) => setIcon(current.Changed, "fa-exclamation-triangle yellow", "Ändringar finns")

const ArticleChanged = (current) => setIcon(current.ArticleChanged, "fa-wrench yellow", "Ändringar finns i underliggande part")

const PurchaseOrderStatus = (current) => `<i class="fas ${getIcon(current.PurchaseOrderStatus)}"></i>`

const IsPlanned = (current) => `
<div class="ma-simple-tooltip">
	${current.IsPlanned > 0 ? '<i class="fas fa-truck green"></i>' : '<i class="fas fa-truck red"></i>'}
	${current.IsPlanned > 0 ? '<div class="ma-simple-tooltip__content">' : ""}
	${current.IsPlanned > 0 ? "Planerad i: " + current.TransportPlanName : ""} 
	${current.IsPlanned > 0 ? "</div>" : ""}
</div>`

const StockStatus = (current) => {
	let items = false;
	let names = false;
	if (current.StockStatus === "DELIVERY" && current.StockIds) {
		items = JSON.parse(current.StockIds);
		names = items
			? items.reduce((acc, curr, index) => {
					const delimiter = index < items.length - 1 ? ", " : "";
					const name =
						curr.s.length > 0 && curr.s[0].po.length > 0 && curr.s[0].po[0].Name1
							? `${curr.s[0].po[0].Name1}-${curr.s[0].po[0].Name2}`
							: curr.s.length > 0
							? curr.s[0].PORef
							: "";
					if (name) {
						acc += name + delimiter;
					}
					return acc;
				}, "")
			: "";
	}
	return `
			<div class="ma-simple-tooltip">
				<i class="fas ${getIcon(current.StockStatus)}"></i>
				${current.IsPreMan === 1 ? ' <i class="fas fa-wrench green"></i>' : ""}
				${items ? '<div class="ma-simple-tooltip__content">' : ""}
				${items ? names : ""}
				${items ? "</div>" : ""}
			</div>`;
}

const StockedAt = (current) => `<i class="fas ${getStockIcon(current.StockStatus)}"></i>`

const NeedsApproval = (current) => `<i title="${
	current.NeedsApproval > 0 ? "Det finns ändringar som måste godkännas" : "Ingen differens behöver godkännas"
}" class="fas ${renderBooleanFromNumber(
	!!current.NeedsApproval,
	"exclamation-triangle red",
	"check green"
)}"></i>`

const Approved = (current) => `<i title="${current.Approved ? "Godkänd" : "Inte godkänd"}" class="fas ${renderBooleanFromNumber(
	!!current.Approved || !current.NeedsApproval,
	"check green",
	"times yellow"
)}"></i>`

const MaterialPlans = (current) => {
	const items = JSON.parse(current.MaterialPlans);
	const names = items
		? items.reduce((acc, curr) => {
				const delimiter = items && items.indexOf(curr) < items.length - 1 ? ", " : "";
				acc += curr.Name + delimiter;
				return acc;
			}, "")
		: "";
	return `
			<div class="ma-simple-tooltip">
				<i class="status-icon status-icon--${items ? "true" : "false"}">${items ? items.length : 0}</i>
				${items ? '<div class="ma-simple-tooltip__content">' : ""}
				${items ? names : ""}
				${items ? "</div>" : ""}
			</div>`;
}

export const useRenderFunctions = () => ({
  pcaCity,
  aIsloggedin,
  oStatusIcon,
  opAmount,
  oStatusPct,
  Deleted,
  Added,
  Changed,
  ArticleChanged,
  PurchaseOrderStatus,
  IsPlanned,
  StockStatus,
  StockedAt,
  NeedsApproval,
  Approved,
	MaterialPlans
});
