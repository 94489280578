<template>
  <Wrapper :name="containerId" :rawName="name" :xPanel="xPanel" :height="height" />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Wrapper from "./molecules/Wrapper.vue";
export default {
  components: {
    Wrapper
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    axisY: {
      type: String,
      required: true
    },
    axisX: {
      type: String,
      required: true
    },
    xPanel: {
      required: false,
      default: true,
      type: Boolean
    },
    height: {
      type: Number,
      required: false
    }
  },
  computed: {
    containerId() {
      return this.$utils.generateId(this.name);
    }
  },
  data() {
    return {
      chart: null
    };
  },
  unmounted() {
    this.chart.dispose();
  },
  methods: {
    getIdFromName() {
      return this.name.toLowerCase();
    },
    init() {
      am4core.ready(() => {
        am4core.useTheme(am4themes_animated);
        this.chart = am4core.create(this.containerId, am4charts.XYChart);
        this.chart.data = this.data;

        var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = this.axisX;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;

        categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
          if (target.dataItem && target.dataItem.index & (2 == 2)) {
            return dy + 25;
          }
          return dy;
        });

        var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        var series = this.chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = this.axisY;
        series.dataFields.categoryX = this.axisX;
        series.name = this.name;
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = 0.8;

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>
