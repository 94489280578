<template>
  <Wrapper :name="containerId" :rawName="name" :xPanel="xPanel" :height="height" />
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Wrapper from "./molecules/Wrapper.vue";
import { generateId } from '@/services/utils';
import { createContainer, createLine, createPie, createColumn, getChartColor } from "./molecules/common";
import { onUnmounted, onMounted } from 'vue';
export default {
  components: {
    Wrapper
  },
  props: {
    name: {
      type: String,
      required: true
    },
    xPanel: {
      required: false,
      default: true,
      type: Boolean
    },
    height: {
      type: Number,
      required: false
		},
		data: {
			type: Array,
			required: true
		}
	},
	setup(props) {
		const containerId = generateId(props.name);
		let container;
		const init = () => {
      am4core.ready(() => {
        am4core.useTheme(am4themes_animated);

        container = createContainer(containerId);
				const colors = new am4core.ColorSet();
				
				props.data.forEach(item => {
					if (item.type == 'pie') {
						createPie(container, item.width, {
							...item.chart,
							color: getChartColor(item)
							});
					}
					if (item.type == 'bar') {
						createColumn(container, item.width, {
							...item.chart,
							color: getChartColor(item)
							});
					}
					if (item.type == 'line') {
						createLine(container, item.width, {
							...item.chart,
							color: getChartColor(item)
							});
					}
				})
      });
		}
		
		onUnmounted(() => {
			container.dispose();
		})

		onMounted(() => {
			init();
		})
		return {
			containerId
		}
	}
};
</script>
