import store from "/src/store/index.js";

export default function role(roles) {

  if(!roles){
      return true;
  }

  const userRole = store.getters.GET_USER.UserRole;
  if(roles.includes(userRole)){
    return true;
  }
  return false;
}