export const getIcon = (name) => {
  switch (name) {
    case "NOT_PLANNED":
      return "fa-times-circle aero";
    case "PLANNED_FROM_STOCK":
      return "fa-warehouse green";
    case "PLANNED_FROM_MRP":
      return "fa-chart-bar green";
    case "DELIVERY":
      return "fa-truck green";
  }
};

export const getStockIcon = (name) => {
  return name ? "fas fa-box" : "";
};

export const setIcon = (val, icon, title = "") => {
  return val == 1 ? `<i title="${title}" class="fas ${icon}"></i>` : "";
};

export const getStatusIcon = (name) => {
  switch (name) {
    case "tillkommit":
      return "fa-plus green";
    case "blocked":
      return "fa-exclamation-triangle red";
    case "removed":
      return "fa-minus red";
    case "changed":
      return "fa-exclamation-triangle yellow";
  }
};

export const renderBooleanFromNumber = (val, yes, no) => {
  return `fa-${val == 1 ? yes : no}`;
};

export const renderStatusIcon = (val) => {
  const value = parseInt(val);
  if (value == 10) {
    return "fa-check-circle";
  }
  if (value > 4) {
    return "fa-running";
  }
  if (value > 0) {
    return "fa-hourglass-start";
  }
};

export const renderProgressIcon = (val) => {
  const value = parseFloat(val.replace(/(\d),(\d)/g, "$1.$2"));
  return value;
};

export const renderOperatorIcon = (val) => {
  const value = parseInt(val);
  if (value == 0) {
    return "fa-user-times fa-2x";
  } else if (value == 1) {
    return "fa-user fa-2x";
  } else if (value > 1) {
    return "fa-user-friends fa-2x";
  } else {
    return "fa-user-times fa-2x";
  }
};

export const renderOperatorLogout = (val) => {
  const value = parseInt(val);
  if (value == 1) {
    return '<button class="button button--green button--margin--auto" >Stämpla Ut <i class="fas fa-sign-in-alt"></i></button>';
  }
};
